 const RenderStatus = ({ item }) => {
    if (item?.status?.toLowerCase() === "completed") {
      let date = new Date(item['created_at']);
      const newDate = date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric' 
      });
      return (
        <span className="text-xs font-normal">{newDate}</span>
      )
    } else if (item?.status?.toLowerCase() === "processing") {
      return (
          <span className="text-xs font-normal">Processing</span>
      )
    } else if (item?.status?.toLowerCase() === "failed") {
      return <span className="text-xs font-normal">Failed</span>
    }
  }

export default RenderStatus;