import { useEffect, useState } from 'react';

import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

import { useShepherdTour } from 'react-shepherd';
import { useUserDetialsContext } from '../../context/user-details';
import newSteps from '../../static/data/shepherd-steps';
import cookieVerify from '../../utils/cookie';
import redirectToLogin from '../../utils/token';

import Button from '../../components/UI/Button';
import FileUpload from '../../components/coverage/FileUpload';
import ConfirmationModal from '../../components/coverage/Modal/ConfirmationModal';
import LimitExceedsModal from '../../components/coverage/Modal/LimitExceedModal';
import MessageModal from '../../components/coverage/Modal/MessageModal';
import ReportGenerationModal from '../../components/coverage/Modal/ReportGenerationModal';
import ReferrerModal from '../../components/coverage/Modal/ReferrerModal';
import Card from '../../components/UI/Card';
import { useSubmissionsContext } from '../../context/submissions';

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
      scrollTo: true,
      scrollToHandler: (element) => {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  useModalOverlay: true
};

const ScriptCoverage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [reportGenerationModal, setReportGenerationModal] = useState(false);
  const [reportGeneration, setReportGeneration] = useState(false);
  const [tokenExceedsModal, setTokenExceedsModal] = useState(false);
  const [tokenExceeds, setTokenExceeds] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const { userDetails } = useUserDetialsContext();
  const { status, site_tour } = userDetails;
  const [showReferModal, setShowReferModal] = useState(false);
  const tour = useShepherdTour({ tourOptions, steps: newSteps });
  const { updateUserTokens } = useUserDetialsContext();
  const { setSubmissionData } = useSubmissionsContext();

  const siteTourDone = async () => {
    const token = cookieVerify(location);
    axios.put(
      `${process.env.REACT_APP_BACKEND_URL}user/tour/complete/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  };

  const showTour = () => {
    if (status && !site_tour) {
      tour.start();
      siteTourDone();
    }
  };
  const suretyClickHandler = (choice) => {
    const isYes = choice === 'yes';
    setConfirmationModal(false);
    if (isYes) {
      if (tokenExceeds) {
        setTokenExceedsModal(true);
        return;
      }
      let isTimeOut = false;
      setTimeout(() => {
        isTimeOut = true;
      }, 240000);
      setReportGenerationModal(true);
      setReportGeneration(true);
      updateUserTokens(0, -1);

      const formData = new FormData();
      formData.append('file', selectedFile);
      const tok = cookieVerify(location);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}generate_script/`, formData, {
          method: 'POST',
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${tok}`
          }
        })
        .then((res) => {
          setReportGenerationModal(false);
          setReportGeneration(false);
          const url = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          const { filename } = res.headers;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          setReportGeneration(false);
          setReportGenerationModal(false);
          if (isTimeOut) {
            setTimeout(() => {
              setShowMessage(true);
            }, 1500);
            return;
          }
          console.log('error', error);
          updateUserTokens(0, 1);
          if (error?.response?.status === 400) {
            toast.error(
              'Something went wrong. Please try again. If the error persists, please contact our support'
            );
          }

          if (error?.response?.status === 401) {
            toast.error('Session expired. Please log in again to continue.');

            // redirecting to login after 2 seconds
            setTimeout(() => {
              redirectToLogin();
            }, 2000);
          }
        });
    }
    const new_script_submission = {
      isArchived: false,
      priority: 'Available Material',
      status: 'PROCESSING',
      type: 'SCRIPT',
      title: selectedFile.name,
      company_name: '',
      note: '',
      created_at: new Date()
    };
    setSubmissionData(prev=>[new_script_submission, ...prev])
  };

  useEffect(() => {
    if (status && !site_tour) {
      setShowReferModal(true);
    }
  }, [status]);

  return (
    <Card>
      <FileUpload
        title="Upload Script"
        subtitle="Upload a script in .pdf, .docx, or .doc format and receive a coverage template in minutes, complete with headers, logline, synopsis, character breakdown, and comps. We've left the comments to you. Please note watermarked copies yield inconsistent or poor results."
        isScript
        setTokenExceeds={setTokenExceeds}
        setTokenExceedsModal={setTokenExceedsModal}
        setConfirmationModal={setConfirmationModal}
        setReportGenerationModal={setReportGenerationModal}
        reportGeneration={reportGeneration}
        setReportGeneration={setReportGeneration}
        setUploadedFile={setSelectedFile}
      />
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        suretyClickHandler={suretyClickHandler}
        isScript
      />
      <ReportGenerationModal
        reportGenerationModal={reportGenerationModal}
        setReportGenerationModal={setReportGenerationModal}
        selectedFile={selectedFile}
      />
      {/* If file is large token > 55000 */}
      <LimitExceedsModal
        tokenExceedsModal={tokenExceedsModal}
        setTokenExceedsModal={setTokenExceedsModal}
      />
      <MessageModal
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        title={<span className="lg:text-lg">Processing in the Background</span>}
        message={
          <div className="flex flex-col items-center gap-6 sm:gap-8 md:gap-12 lg:gap-14">
            <p className="font-semibold self-start">
              It&apos;s taking longer than expected. Please check your email for the completed
              report.
            </p>
            <Button
              className="!font-inter !font-semibold"
              onClick={() => {
                setShowMessage(false);
              }}>
              Close
            </Button>
          </div>
        }
        noBottomMargin
      />
      <ReferrerModal
        showMessage={showReferModal}
        setShowMessage={setShowReferModal}
        showTour={showTour}
      />
    </Card>
  );
};

export default ScriptCoverage;
