import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../static/images/logo.png';
import cookieVerify from '../../utils/cookie';
import Button from '../UI/Button';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loginClickHandler = () => {
    const tokenValue = cookieVerify(location);
    if (tokenValue) {
      navigate(`/script-coverage/#id_token=${tokenValue}`);
    } else {
      navigate('/login', { state: { prevLandingPage: true } });
    }
  };

  const signUpClickHandler = () => {
    const tokenValue = cookieVerify(location);
    if (tokenValue) {
      navigate(`/script-coverage/#id_token=${tokenValue}`);
    } else {
      navigate('/signup', { state: { prevLandingPage: true } });
    }
  };
  return (
    <nav className="relative flex flex-col gap-4 md:gap-6 lg:flex-row items-center justify-between mt-4 md:mt-5">
      <img src={Logo} alt="Fullframe" className="h-[70px] cursor-pointer" />
      <div className="flex items-center gap-2 md:gap-4">
        <Button onClick={signUpClickHandler}>Start Coverage Now</Button>
        <Button
          className="!p-1 md:!py-3 md:!px-5 !bg-transparent hover:!bg-transparent hover:text-primary-dark rounded-md"
          onClick={loginClickHandler}
        >
          Log in
        </Button>
        <Button
          element="link"
          to="https://calendly.com/fullframe-support/30min?month=2023-09"
          className="!p-1 md:!py-3 md:!px-5 !bg-transparent hover:!bg-transparent hover:text-primary-dark rounded-md"
        >
          Book a Demo
        </Button>
      </div>
    </nav>
  );
};

export default Navbar;
