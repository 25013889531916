import { styled, Tooltip, tooltipClasses } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { DispalyPropertiesIcon } from '../../icons/icons';
import RenderDropdownPills from './RenderDropdownPills';

const DisplayPropertiesDropdown = () => {
  const [openDisplayPropertiesDropdown, setOpenDisplayPropertiesDropdown] = useState(false);

  const displayDropdownClose = () => {
    setOpenDisplayPropertiesDropdown(false);
  };

  const toggleDisplayDropdown = () => {
    setOpenDisplayPropertiesDropdown((prev) => !prev);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: 'linear-gradient(rgba(254, 102, 41, 0.05), rgba(254, 102, 41, 0.05)), #101828',
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '1rem',
      marginTop: '4px !important',
      width: 245,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #475467',
      borderRadius: '8px',
      boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.15)'
    }
  }));

  const tableHeadings = [
    'Title',
    'Writers/Directors/Producers',
    'Submitted by',
    'Genres',
    'Logline',
    'Note',
    'Read',
    'Responded'
  ];

  return (
    <Grid item>
      <ClickAwayListener onClickAway={displayDropdownClose}>
        <div>
          <HtmlTooltip
            PopperProps={{
              disablePortal: true
            }}
            onClose={displayDropdownClose}
            open={openDisplayPropertiesDropdown}
            placement="bottom-start"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<RenderDropdownPills headings={tableHeadings} />}>
            <button
              onClick={toggleDisplayDropdown}
              className="px-3.5 py-2 text-sm bg-[#292A35] border border-[#393A4B] rounded-md font-inter shadow-[0px_1px_1px_0px_rgba(#00000026)]">
              <DispalyPropertiesIcon className="inline-block mr-2" />
              <span>Display</span>
            </button>
          </HtmlTooltip>
        </div>
      </ClickAwayListener>
    </Grid>
  );
};

export default DisplayPropertiesDropdown;
