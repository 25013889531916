import axios from 'axios';
import cookieVerify from '../utils/cookie';

// Sending the custom template file to the backend
const sendTemplateFile = async (data, location) => {
  const formData = new FormData();
  let res;
  for (const key in data) {
    if (data) {
      formData.append(key, data[key]);
    }
  }
  const tok = cookieVerify(location);
  try {
    res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}custom_template/`, formData, {
      method: 'POST',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${tok}`,
      },
    });
  } catch (err) {
    return { message: "Something went wrong" };
  }
  return res;
};

export default sendTemplateFile;
