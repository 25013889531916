import React from 'react';

const TableTextAreaField = ({
  value,
  onChange,
  onKeyDown = () => {},
  onBlur,
  onFocus,
  className = '',
  id = '',
  rows,
  cols,
  style
}) => {
  return (
    <textarea
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`px-2 py-1 bg-[#292A3580] border-[rgba(255,255,255,0.2)] border border-tansparent resize-y rounded outline-none ${className}`}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      rows={rows}
      cols={cols}
      id={id}
      title={value}
      style={style}
    />
  );
};

export default TableTextAreaField;
