import { Helmet } from 'react-helmet';

import { content } from '../static/data/TermsOfUseContent';
import Card from '../components/UI/Card';

function TermsOfUse() {
  return (
    <Card className="flex flex-col gap-4 md:gap-6">
      <Helmet>
        <title>Terms Of Use | FullFrame</title>
        <meta
          name="description"
          content="Please read these Terms of Use (the “Terms”) carefully because they are a legal agreement
          that governs the website of FullFrame, Inc. (“FullFrame”) located at..."
        />
      </Helmet>
      <h3 className="text-lg md:text-xl font-filsonPro-bold">Terms Of Use</h3>
      <div className="flex flex-col gap-6 font-filsonPro-book">
        <p>
          Please read these Terms of Use (the “Terms”) carefully because they are a legal agreement
          that governs the website of FullFrame, Inc. (“FullFrame”) located at &nbsp;
          <a href="http://www.fullfr.me" rel="noreferrer" target="_blank" className="text-primary">
            http://www.fullfr.me
          </a>
          (the “Site”) and the User Components (the “Support Services”).
        </p>
        <p>
          By using the Support Services, which may include accessing and using User Components in
          connection with FullFrame, you agree to be bound by these Terms and the Privacy notice
          referenced in Section 1 below. If you don&apos;t agree to be bound by these Terms and the
          Privacy notice, do not use the Support Services.
        </p>
        {content.map((item, index) => (
          <div key={item.title} className="flex flex-col gap-[0.625rem]">
            <h4 className="text-primary font-filsonPro-semibold">{item.title}</h4>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default TermsOfUse;
