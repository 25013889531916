import React from 'react';
import { useNavigate } from 'react-router-dom';

const RenderSessionItem = ({ title, items, containerClassName = '' }) => {
  const navigate = useNavigate();

  const handleSessionClick = (chatId) => {
    const params = chatId ? `?chat_id=${chatId}` : '';
    navigate(`/query${params}`);
  };

  return (
    items?.length > 0 && (
      <div className={`${containerClassName}`}>
        <h3 className="text-[0.8125rem] leading-5 text-coolGray font-bold italic">{title}</h3>
        <ul className="mt-1.5">
          {items.map(
            (session) =>
              session?.name?.trim() && (
                <li
                  key={session.chat_id}
                  onClick={() => handleSessionClick(session._id)}
                  className={`text-[0.8125rem] cursor-pointer transition duration-200 ease-in-out hover:text-primary py-1.5`}>
                  <p
                    className={`font-bold overflow-hidden text-ellipsis whitespace-nowrap w-full italic`}>
                    {session.name}
                  </p>
                </li>
              )
          )}
        </ul>
      </div>
    )
  );
};

export default RenderSessionItem;
