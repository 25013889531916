import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { updateSubmissionDataApi } from "../../api/submissions-database";
import { useSubmissionsContext } from "../../context/submissions";
import Button from "../UI/Button";
import RenderInitials from "./RenderInitials";

const SubmittedByDropdown = ({ item, label = "", containerClass = "", dropdownClass = "", isBoolean = false, setCurrentDropdown }) => {
  const dropdownRef = useRef(null);
  const location = useLocation();
  const copmanyNameInputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const { setSubmissionData } = useSubmissionsContext();
  const [companyName, setCompanyName] = useState(item.company_name);
  const [submittedBy, setSubmittedBy] = useState(item.submitted_by);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsVisible(false);
    setHighlightedIndex(-1);
    if (companyName === item.company_name && submittedBy === item.submitted_by || !companyName || !submittedBy) return;
    setSubmissionData((prevData) => {
      return prevData.map((data) => {
        if (data._id === item._id) {
          return { ...data, company_name: companyName, submitted_by: submittedBy };
        }
        return data;
      });
    });

    await updateSubmissionDataApi({data: { _id: item._id, "submitted_by": submittedBy, "company_name": companyName }, location });
  };

  const toggleVisibility = () => {
    
    if (!isVisible) {
      setCurrentDropdown(label);
      // Focus on the input field when the dropdown is opened
      setTimeout(() => {
        copmanyNameInputRef.current?.focus();
      }, 60);
    }
    setIsVisible((prev) => !prev);

  };
  
  const onBlurHandler = (event) => {
    if (!dropdownRef.current.contains(event.relatedTarget)) {
      setIsVisible(false);
      setHighlightedIndex(-1);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Escape') {
      // Closing the dropdown on ESC
      setIsVisible(false);
    } 
  };

  useEffect(() => {    
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);

  }, [highlightedIndex]);

  return (  
    <div className={`relative ${containerClass} min-w-fit`} onBlur={onBlurHandler} tabIndex={0} ref={dropdownRef}>
        <div className={ "flex justify-between items-center gap-1 text-xs rounded-full px-2 py-0.5 text-whie bg-transparent w-full cursor-pointer"}
          onClick={toggleVisibility}
        >
         {item?.company_name?.trim() !== "" && item?.submitted_by?.trim() !== "" ?
            <>
              <RenderInitials companyName={item?.company_name} />
              <span>{item?.submitted_by}</span>
            </>
          :
            "---"
         }
        </div>
        <form onSubmit={onSubmitHandler} className={`absolute top-7 rounded-md text-[13px] text-left p-3 bg-[rgba(49,50,62,1)] border border-[rgba(255,255,255,0.1)] text-white z-30 
            ${isVisible ? "visible" : "invisible"} shadow-[0px_4px_24px_rgba(0,0,0,0.2)] ${dropdownClass} font-inter w-fit`}
        >
            <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                    <label htmlFor="company-name" className="text-sm text-[#98A2B3] font-normal">Company Name</label>
                    <input
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        id="company-name"
                        className="w-[200px] text-sm bg-[#37384580] text-white px-5 py-3 border-b border-[#52526F40] rounded outline-none"
                        ref={copmanyNameInputRef}
                        placeholder={`Enter company name`}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="submitted-by" className="text-sm text-[#98A2B3] font-normal">Submitted by</label>
                    <input
                        type="text"
                        value={submittedBy}
                        onChange={(e) => setSubmittedBy(e.target.value)}
                        id="submitted-by"
                        className="w-[200px] text-sm bg-[#37384580] text-white px-5 py-3 border-b border-[#52526F40] rounded outline-none"
                        placeholder={`Submitted by`}
                    />
                </div>
            </div>
            <div className="flex gap-3 items-center w-full mt-4">
                <Button className={'w-full !bg-[#292A35] border border-[#393A4B] !py-2 text-sm hover:!bg-[#393A4B]'}
                    onClick={() => setIsVisible(false)}
                >
                    Cancel
                </Button>
                <Button type={"submit"} className={'w-full !py-2 text-sm'}>
                    Submit
                </Button>
            </div>
      </form>
    </div>
  );
};

export default SubmittedByDropdown;