import { Helmet } from 'react-helmet';
import { useRoutes } from 'react-router-dom';

import {
  Balance,
  Payment
  // SubscriptionCheckout,
} from '../billing/Billing';
import { BookCoverage, ScriptCoverage } from '../coverage/Coverage';
import MainLayout from '../layouts/MainLayout';
import Login from '../login/Login';
import Signup from '../signup/Signup';
// import PaymentMethod from "../billing/PaymentMethods";
import AboutUs from '../about-us/AboutUs';
import Contact from '../contact/Contact';
import CustomTemplate from '../custom-template/CustomTemplate';
import DataRetention from '../data-retention/DataRetention';
import Faq from '../faq/Faq';
import LandingPage from '../landing/LandingPage';
import SimpleLayout from '../layouts/SimpleLayout';
import UnprotectedLayout from '../layouts/UnprotectedLayout';
import NotFoundPage from '../page-404/Page404';
import PaymentComplete from '../payment-complete/PaymentComplete';
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy';
import QueryScript from '../query-script/QueryScript';
import Query from '../query/Query';
import Refer from '../refer/Refer';
import SubmissionsDatabase from '../submissions-database/SubmissionsDatabase';
import TermsOfUse from '../terms-of-use/TermsOfUse';
import HelmetComponent from './HelmetComponent';
import { useUserDetialsContext } from '../context/user-details';

export default function Router() {
  const {
    userDetails: { show_chat }
  } = useUserDetialsContext();

  const routes = [
    {
      path: '/',
      element: (
        <>
          <HelmetComponent
            cannonical={'/'}
            title="Home | FullFrame"
            description="Streamline your coverage workflow with our dedicated app and say goodbye to hours of tedious summarizing. Securely upload your script, and within minutes, FullFrame drafts a comprehensive, automated coverage report, sans comments. FullFrame can process pilots, TV scripts, and movie scripts."
          />
          <LandingPage />
        </>
      )
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/signup',
      element: <Signup />
    },
    {
      path: '',
      element: <MainLayout />,
      children: [
        {
          path: 'script-coverage',
          element: (
            <>
              <HelmetComponent
                cannonical={'/script-coverage'}
                title="Script Coverage | FullFrame"
                description="Streamline your coverage workflow with our dedicated app and say goodbye to hours of tedious summarizing. Securely upload your script, and within minutes, FullFrame drafts a comprehensive, automated coverage report, sans comments. FullFrame can process pilots, TV scripts, and movie scripts."
              />
              <ScriptCoverage />
            </>
          )
        },
        {
          path: 'script-coverage/:id',
          element: (
            <>
              <HelmetComponent
                cannonical={'/script-coverage'}
                title="Script Coverage | FullFrame"
                description="Streamline your coverage workflow with our dedicated app and say goodbye to hours of tedious summarizing. Securely upload your script, and within minutes, FullFrame drafts a comprehensive, automated coverage report, sans comments. FullFrame can process pilots, TV scripts, and movie scripts."
              />
              <ScriptCoverage />
            </>
          )
        },
        {
          path: 'book-coverage',
          element: (
            <>
              <HelmetComponent
                cannonical={'/book-coverage'}
                title="Book Coverage | FullFrame"
                description="Streamline your coverage workflow with our dedicated app and say goodbye to hours of tedious summarizing. Securely upload your book, and within minutes, FullFrame drafts a comprehensive, automated coverage report, sans comments. FullFrame can process any length of book, including books over 1,000 pages."
              />
              <BookCoverage />
            </>
          )
        },
        {
          path: 'material-log',
          element: (
            <>
              <HelmetComponent
                title="Material Log | FullFrame"
                description="All your submissions in one place. Keep track of your submissions, see their status, and get notified when they are read."
              />
              <SubmissionsDatabase />
            </>
          )
        },
        {
          path: 'about-us',
          element: (
            <>
              <HelmetComponent
                cannonical={'/about-us'}
                title="About Us | FullFrame"
                description="Learn more about FullFrame and our mission to revolutionize Hollywood Development."
              />
              <AboutUs />
            </>
          )
        },
        {
          path: 'billing',
          children: [
            {
              path: 'balance',
              element: <Balance />
            }
          ]
        },
        {
          path: 'contact-us',
          element: (
            <>
              <HelmetComponent
                cannonical={'/contact-us'}
                title="Contact Us | FullFrame"
                description="Contact FullFrame for support or inquiries."
              />
              <Contact />
            </>
          )
        },
        {
          path: 'faq',
          element: <Faq />
        },
        {
          path: 'terms-of-use',
          element: <TermsOfUse />
        },
        {
          path: 'privacy-policy',
          element: <PrivacyPolicy />
        },
        {
          path: 'referral-program',
          element: <Refer />
        },
        {
          path: 'custom-template',
          element: <CustomTemplate />
        },
        {
          path: 'data-retention',
          element: <DataRetention />
        }
      ]
    },
    {
      path: '',
      element: <SimpleLayout />,
      children: [
        {
          path: 'billing',
          children: [
            {
              path: 'subscription',
              element: <Payment />
            },
            {
              path: 'checkout/:product',
              element: <Payment />
            }
          ]
        }
      ]
    },
    {
      path: '',
      element: <UnprotectedLayout />,
      children: [
        {
          path: 'contact_us',
          element: <Contact className="p-2 sm:p-5 md:p-8" />
        }
      ]
    },
    {
      path: '*',
      element: (
        <>
          <Helmet>
            <title>Page Not Found | FullFrame</title>
            <meta name="description" content="Oops! The page you're looking for does not exist." />
          </Helmet>
          <NotFoundPage />
        </>
      )
    },
    {
      path: 'payment-complete',
      element: <PaymentComplete />
    }
  ];

  if (show_chat) {
    routes[3].children.push({
      path: 'query',
      element: (
        <>
          <HelmetComponent
            title="Query | FullFrame"
            description="Query the uploaded script and get the coverage report in PDF format."
          />
          <Query />
        </>
      )
    });
  }

  return useRoutes(routes);
}
