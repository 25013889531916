import { useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import sendTemplateFile from '../api/custom-template';
import FileUpload from '../components/coverage/FileUpload';
import TemplateConfirmationModal from '../components/custom-template/TemplateConfirmationModal';
import Card from '../components/UI/Card';

const CustomTemplate = () => {
  const location = useLocation();
  const [uploadInProgress, setUploadInProgess] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);

  const suretyClickHandler = async (choice) => {
    const isYes = choice === 'yes';
    let loadingToast;
    setConfirmationModal(false);
    if (isYes) {
      setUploadInProgess(true);
      loadingToast = toast.loading('Sending your custom template...');
      const response = await sendTemplateFile(
        { file: selectedFile, fileType: selectedFile.type },
        location,
      );
      toast.dismiss(loadingToast);
      if (response.status === 200) {
        setIsMailSent(true);
        toast.success('Custom template sent successfully!');
      } else if (response.message) {
        if (response.message === "Unauthorized user") {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        toast.error(response.message);
      } else {
        toast.error('Something went wrong');
      }
      setUploadInProgess(false);
    }
  };

  return (
    <Card>
      <FileUpload
        title="Upload Custom Coverage Template"
        subtitle="Upload your own coverage template in .doc or .docx format and we will update your account in 1 to 2 days to create reports with the custom template going forward."
        isCustomTemplate
        setUploadedFile={setSelectedFile}
        setConfirmationModal={setConfirmationModal}
        isMailSent={isMailSent}
        uploadInProgress={uploadInProgress}
      />
      <TemplateConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        suretyClickHandler={suretyClickHandler}
      />
    </Card>
  );
};
export default CustomTemplate;
