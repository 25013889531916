
export const content = [
  {
    title: 'Privacy Policy',
    subtitle: '1.1. Account creation',
    description:
      'If you create an account with FullFrame, we will collect certain personal information about you, such as your name, email address, user ID, and authentication information.'
  },
  {
    title: '',
    subtitle: '1.2 Communications with Us',
    description:
      'We may collect personal information from you such as your email address when you request information about or show interest in our Services or Software, register for our newsletter, request customer or technical support, communicate with us, or otherwise provide personal information to us.'
  },
  {
    title: '',
    subtitle: '1.3. Data collected in connection with our Services',
    description:
      'We collect the information you provide to the Services (including any personal information that you choose to include when using the Services, such as your name and/or email address).'
  },
  {
    title: '',
    subtitle: '1.4. Surveys',
    description:
      'We may contact you to participate in surveys. If you decide to participate, we may ask you for certain information which may include personal information.'
  },
  {
    title: '',
    subtitle: '1.5. Community features',
    description:
      'We may offer community features such as forums, blogs, or social media pages. Any content you provide on the public sections of these channels will be publicly accessible and is not subject to privacy protections referenced herein.'
  },
  {
    title: '',
    subtitle: '1.6. Information we automatically collect',
    description:
      'We may also collect certain information automatically when you use the Services. This information may include your Internet protocol (IP) address, user settings, unique identifiers associated with your browser or device (including Technologies), details about your browser, operating system or device, location information (including inferred location based off of your IP address), Internet service provider, pages that you visit before, during and after using the Services, information about the links you click, and other information about how you use the Services. We may also monitor your use of the Software, and compile information related to such use, including statistical and performance information related to the operation and use of the Software (“Usage Data”). As between FullFrame and you, all right, title and interest in the Usage Data and all intellectual property rights therein, belong to and are retained solely by us, and we may use such Usage Data for any purpose permitted by law, including for data gathering, analysis, Software and Services enhancements and improvements, marketing, and as may be required by applicable law or regulation.'
  },
  {
    title: '',
    subtitle: '1.7. Cookies, pixel tags/web beacons, and analytics information',
    description:
      'We, as well as third parties that may provide content or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to collect information through the Services. Technologies are essentially small data files placed on your device that allow us and our partners to record certain pieces of information when you visit or interact with our Services. Our use of such Technologies falls into the following categories: (i) operationally necessary Technologies; (ii) personalization Technologies; and (iii) analytics or measurement Technologies.'
  },
  {
    title: '',
    subtitle: '1.7.1 Cookies',
    description:
      'Cookies are small text files placed in device browsers to store their preferences. We and our third-party partners use cookies for different reasons, including to see which areas and features are popular and to count visits to our Sites. Some cookies are required for technical reasons in order for our Sites to operate. Other cookies enable us to track and target the interests of our users to enhance the experience on our Sites (see section 2.7.3). Most browsers allow you to block and delete some or all of the cookies used by a site. However, note that if you choose to block or delete all cookies, the Services may not work properly.'
  },
  {
    title: '',
    subtitle: '1.7.2 Pixel tags/web beacons',
    description:
      'We may include web beacons in e-mails to understand whether messages have been opened, acted on, or forwarded.'
  },
  {
    title: '',
    subtitle: '1.7.3 Analytics',
    description:
      'We may use Google Analytics and other service providers to collect and process analytics information on our Services. For more information about how Google uses data, please visit www.google.com/policies/privacy/partners/. You can opt out of Google Analytics’ collection and processing of data generated by your use of our website by going to http://tools.google.com/dlpage/gaoptout. How do we use your information? We use your personal information for a variety of business purposes, including:'
  },
  {
    title: '',
    subtitle: '2.1. To provide the services and software, such as:',
    description:
      'Fulfilling our contract with you; Responding to your questions, comments, and other requests; Processing your payment card or other financial information for products or Services purchased; and Providing access to certain areas, functionalities, and features of our Services.'
  },
  {
    title: '',
    subtitle: '2.2. Administrative purposes, such as:',
    description:
      'Pursuing our legitimate interests, such as research and development (including marketing research), network and information security, and fraud prevention; Measuring interest and engagement in the Services and Software; Improving the Services and Software; Developing new products and services; Ensuring internal quality control and safety; Authenticating and verifying individual identities; Carrying out audits; Communicating with you about your account, the Services and the Software; Preventing and prosecuting potentially prohibited or illegal activities; Enforcing our agreements; and Complying with our legal obligations.'
  },
  {
    title: '',
    subtitle: '2.3. Marketing our products, services, and software',
    description:
      'We may use personal information to tailor and provide you with product announcements and other content. We may provide you with these materials as permitted by applicable law. If you have any questions about our marketing practices or if you would like to opt out of the use of your personal information for marketing purposes, you may contact us at any time as set forth below.'
  },
  {
    title: '',
    subtitle: '2.4. Purposes for which You consent',
    description:
      'We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent, as required.'
  },
  {
    title: '',
    subtitle: '2.5. De-identified and aggregated information use',
    description:
      'We may use personal information and other information about you to create de-identified and/or aggregated information. De-identified and/or aggregated information is not personal information, and we may use and disclose such information in a number of ways, including research, internal analysis, analytics, and any other legally permissible purposes.'
  },
  {
    // title: "To Whom Do We Disclose Your Personal Information?",
    title: '',
    subtitle: '3.1. Service providers',
    description:
      'We may disclose your personal information with our service providers for the purposes described in Section 3 (How do we use your information).'
  },
  {
    title: '',
    subtitle: '3.2. Disclosures to protect us or others',
    description:
      'We may access, preserve, and disclose any information we store in association with you to external parties if we, in good faith, believe doing so is required or appropriate to: (i) comply with law enforcement or national security requests and legal process, such as a court order or subpoena; (ii) protect your, our, or others’ rights, property, or safety; (iii) enforce our policies or contracts; (iv) collect amounts owed to us; or (v) assist with an investigation or prosecution of suspected or actual illegal activity.'
  },
  {
    title: '',
    subtitle: '3.3. Merger, sale, or other asset transfers',
    description:
      'If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, then your information may be sold or transferred as part of such a transaction as permitted by law and/or contract.'
  },
  {
    // title: "4. International data transfers",
    title: '',
    subtitle: '4.1 Data Transfer',
    description:
      'All information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live. We endeavor to safeguard your information consistent with the requirements of applicable laws.'
  },
  {
    // title: "5. Choices",
    title: '',
    subtitle: '5.1. General',
    description: 'You may have the right to opt out of certain uses of your personal information.'
  },
  {
    // title: '',
    subtitle: '5.2. Email communications',
    description:
      'You can unsubscribe from FullFrame emails by using the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transaction-related emails, and you will not be able to opt out of those communications (e.g., communications regarding the Services, or updates to our Terms or this Privacy Notice).'
  },
  {
    // title: '',
    subtitle: '5.3. “Do not track”',
    description:
      'Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.'
  },
  {
    // title: '',
    subtitle: '5.4. Cookies',
    description:
      'You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits. Please note you must separately opt out in each browser and on each device.'
  },
  {
    // title: "6. California Users: Your California privacy rights",
    title: '',
    subtitle: '6.1. The California Consumer Privacy Act of 2018',
    description:
      'As amended by the California Privacy Rights Act of 2020 (“CCPA”), effective as of January 1, 2023, requires businesses that collect personal information of California residents to make certain disclosures regarding how they collect, use, retain and disclose such information. This section addresses those requirements. For a description of all of our data collection, use and disclosure practices, please read this Privacy Notice in its entirety. 6.2. Personal Information Collection and Disclosure: FullFrame does not sell personal information as we understand the term sale to be defined by the CCPA and its implementing regulations. FullFrame does share personal information.'
  },
  {
    subtitle: '6.2. Personal Information Collection and Disclosure',
    description:
      'FullFrame does not sell personal information as we understand the term sale to be defined by the CCPA and its implementing regulations. FullFrame does share personal information.'
  },
  {
    // title: "7. Privacy Rights",
    title: '',
    subtitle: '7.1. You may have privacy rights regarding your personal information',
    description:
      'You may request a copy of your personal information or request to correct, update, or delete your personal information.'
  },
  {
    subtitle: '7.2. You may request that we disclose what personal information we collect',
    description:
      'Including the categories of personal information, the categories of sources from which the personal information is collected, the business or commercial purpose for collecting, selling, or sharing personal information, and the categories of third parties to whom we disclose personal information.'
  },
  {
    subtitle:
      '7.3. Where required by law, you can object to processing of your personal information',
    description:
      'You can ask us to restrict processing of your personal information, or request portability of your personal information.'
  },
  {
    subtitle: '7.4. If we collected and processed your personal information with your consent',
    description:
      'Where required by law, you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.'
  },
  {
    subtitle:
      '7.5. Californians also have the right to opt out of the sale of personal information',
    description:
      'Or the sharing of personal information for cross-context behavioral advertising, as well as the right to limit the use or disclosure of sensitive personal information for purposes other than those permitted by the CCPA if sensitive personal information is collected. As described above, we do not sell your personal information, or use or disclose sensitive personal information for purposes other than those permitted by the CCPA.'
  },
  {
    subtitle: '7.6. FullFrame does not discriminate against you for exercising your rights',
    description:
      'Or offer you financial incentives related to the use of your personal information.'
  },
  {
    subtitle: '8. Data retention',
    description:
      '8.1. FullFrame retains the personal information we receive as described in this Privacy Notice for as long as you use our Services or Software or as necessary to fulfill the purposes for which it was collected.'
  },
  {
    subtitle: '9. Security',
    description:
      '9.1. We take steps to ensure that your information is treated securely and in accordance with this Privacy Notice. Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable law, we do not accept liability for unauthorized disclosure. We may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services or Software. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Services, by mail or e-mail.'
  },
  {
    subtitle: '10. Third-Party websites and applications',
    description:
      '10.1. The Services and Software may contain links to other websites/applications and other websites/applications may reference or link to us. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve, and are not responsible for, the privacy practices or content of such other websites or applications. Providing personal information to third party websites or applications is at your own risk.'
  },
  {
    subtitle: '11. Changes to our privacy policy',
    description:
      '11.1. We may revise this Privacy Notice from time to time in our sole discretion. If there are any material changes to this Privacy Notice, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Notice if you continue to use the Support Services or Software after the new Privacy Notice takes effect.'
  },
  {
    subtitle: '12. Contact us',
    description: '12. Contact us    '
  }
];
