import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  addSubmissionDataApi,
  getSubmissionsDataApi,
  getSubmissionsLongDataApi
} from '../api/submissions-database';
import Button from '../components/UI/Button';
import PlusIcon from '../components/icons/PlusIcon';
import { ExportIcon } from '../components/icons/icons';
import { SpinnerSmall } from '../components/spinner/Spinner';
import SubmissionsTable from '../components/table/SubmissionsTable';
import { useSubmissionsContext } from '../context/submissions';
import { exportSubmissions } from '../utils/exportSubmissions';
import { sortDataByFields } from '../utils/sortDataByFields';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { toast } from 'react-hot-toast';
import DisplayPropertiesDropdown from '../components/submissions-database/dropdown/DisplayPropertiesDropdown';
import isMoreThanAnHourFormNow from '../utils/isMoreThanAnHourFormNow';
import redirectToLogin from './../utils/token';

const SubmissionsDatabase = () => {
  const location = useLocation();
  const { submissionsData, setSubmissionData, loading, setLoading, setHiddenColumns } =
    useSubmissionsContext();
  const [csvExportInProgress, setCsvExportInProgress] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const headings = [
    {
      label: '',
      name: 'type',
      customHeadingClass: 'w-7 !pr-2'
    },
    {
      label: 'Title',
      name: 'title',
      sortable: true
    },
    {
      label: 'Writers/Directors/Producers',
      name: 'Writer/Directors/Producers'
    },
    {
      label: 'Submitted by',
      name: 'submittedBy'
    },
    {
      label: 'Genres',
      name: 'genre'
    },
    {
      label: 'Logline',
      name: 'logline'
    },
    {
      label: 'Note',
      name: 'note'
    },
    {
      label: 'Read',
      name: 'read',
      sortable: true
    },
    {
      label: 'Responded',
      name: 'response_status',
      sortable: true
    },
    {
      label: 'Priority',
      name: 'priority',
      sortable: true
    },
    {
      label: 'Date',
      name: 'created_at',
      sortable: true
    },
    {
      label: '',
      name: 'actions'
    }
  ];

  const setAndSortSubmissionsList = (submissionList) => {
    const sortCriteria = localStorage.getItem('sortingCriteria');
    if (sortCriteria) {
      const sortedData = sortDataByFields(submissionList, JSON.parse(sortCriteria));
      setSubmissionData(sortedData);
    } else {
      setSubmissionData(submissionList);
    }
  };
  const addSubmission = async () => {
    setInProgress(true);
    const newSubmission = {
      read: false,
      response_status: false,
      isArchived: false,
      priority: 'General',
      status: 'COMPLETED',
      type: 'SCRIPT',
      title: '',
      writer: [],
      director: [],
      producers: [],
      company_name: '',
      submitted_by: '',
      genre: [],
      logline: '',
      note: '',
      publisher: [],
      agency: ''
    };

    const presentFields = { ...newSubmission };
    delete presentFields.agency;
    delete presentFields.publisher;

    try {
      const response = await addSubmissionDataApi({ data: newSubmission });
      const newId = response.data.data._id;
      const createdSubmission = { ...newSubmission, _id: newId, created_at: new Date() };

      setSubmissionData((prevData) => {
        return [createdSubmission, ...prevData];
      });
    } catch (error) {
      console.error('Error adding submission:', error);
    } finally {
      setInProgress(false);
    }
  };

  const handleCSVExport = async () => {
    try {
      setCsvExportInProgress(true);
      const response = await getSubmissionsDataApi(location);
      const submissionList = response?.data?.data;

      if (!submissionList) {
        console.error('No data returned from API');
        return;
      }

      const sortCriteria = localStorage.getItem('sortingCriteria');
      if (sortCriteria) {
        const sortedData = await sortDataByFields(submissionList, JSON.parse(sortCriteria));
        exportSubmissions(sortedData);
      } else {
        exportSubmissions(submissionList);
      }
    } catch (error) {
      console.error('Error fetching submissions data:', error);
    } finally {
      setCsvExportInProgress(false);
    }
  };

  const renderExportCsvIcon = () => {
    if (csvExportInProgress) return <SpinnerSmall />;
    else
      return (
        <>
          <ExportIcon /> <span> Export Table</span>
        </>
      );
  };

  const renderAddRowLabel = () => {
    if (inProgress) return <SpinnerSmall />;
    else
      return (
        <>
          <PlusIcon />
          <span>Add Row</span>
        </>
      );
  };

  const submissionLongPolling = async () => {
    try {
      const isProcessing = submissionsData?.some((item) => {
        return item.status === 'PROCESSING' && !isMoreThanAnHourFormNow(item?.created_at);
      });
      if (!isProcessing) return;
      const response = await getSubmissionsLongDataApi(location);
      const submissionList = response?.data?.data;
      setAndSortSubmissionsList(submissionList);
    } catch (error) {
      console.log('error', error);
      if (error?.response?.status === 408) {
        submissionLongPolling();
      } else if (error?.response?.status === 401) {
        toast.error('Session expired. Please log in again to continue.');
        setTimeout(() => {
          redirectToLogin();
        }, 2000);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submissionsData?.length > 0 && !loading) {
      submissionLongPolling();
    }
    const storedHiddenColumns = localStorage.getItem('hiddenColumns');
    if (storedHiddenColumns) {
      setHiddenColumns(JSON.parse(storedHiddenColumns));
    }
  }, [submissionsData]);

  const activeData = submissionsData.filter((record) => !record?.isArchived);
  const archivedData = submissionsData.filter((record) => record?.isArchived);
  const scaledContainerStyle = css`
    transform-origin: top left;

    @media (max-width: 1800px) {
      transform: scale(0.75);
    }

    @media (min-width: 1800px) and (max-width: 2800px) {
      transform: scale(1);
    }

    @media (min-width: 2801px) {
      transform: scale(1);
    }
  `;

  return (
    <section className="z-[50] database-submissions-container submission-table-container">
      <div
        className={
          'flex items-center justify-center sm:justify-end gap-2 sm:gap-4 lg:gap-5 border border-[#1D2939] px-4 sm:px-3 md:px-4 lg:px-6 !py-3.5 rounded-xl'
        }>
        <Button
          element={'link'}
          to="/script-coverage"
          className={'flex items-center gap-2 md:!px-3.5 md:!py-2 font-inter !rounded'}>
          <PlusIcon />
          <span>Generate Coverage</span>
        </Button>
        <button
          type="button"
          disabled={csvExportInProgress}
          className="flex justify-center items-center w-[150px] h-[40px] gap-2 md:px-3.5 py-2 font-inter border border-[#393A4B] bg-[#292A35] hover:bg-[#393A4B] shadow-[0px_1px_1px_rgba(0,0,0,0.15)] rounded transition-all duration-300"
          onClick={handleCSVExport}>
          {renderExportCsvIcon()}
        </button>
      </div>
      <div className="flex justify-between pt-8 px-4 sm:px-3 md:px-4 lg:px-6 py-4">
        <h2 className="text-lg font-inter">Submissions</h2>
        <div className="flex items-center gap-2 sm:gap-4 lg:gap-5">
          <Button
            className={
              'flex justify-center items-center !w-[130px] !h-[35px] gap-2 md:!px-3.5 md:!py-2 font-inter !rounded'
            }
            onClick={addSubmission}
            disabled={inProgress}>
            {renderAddRowLabel()}
          </Button>
          <DisplayPropertiesDropdown />
        </div>
      </div>
      <div css={scaledContainerStyle}>
        <div className={'p-0 rounded-[10px] w-full'}>
          <div className="relative h-full w-full">
            <SubmissionsTable
              loading={loading}
              filterSubmissionData={activeData}
              headings={headings}
            />
          </div>
        </div>
        <div className={'mt-8 p-0 rounded-[10px] w-full'}>
          <div className="flex justify-between px-4 sm:px-3 md:px-4 lg:px-6 py-4">
            <h2 className="text-lg font-inter">Archive</h2>
          </div>
          <div className="relative h-full w-full">
            <SubmissionsTable
              loading={loading}
              filterSubmissionData={archivedData}
              headings={headings}
              isArchiveTable
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubmissionsDatabase;
