import { useEffect, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useStripe } from '@stripe/react-stripe-js';

import ConfirmationModal from '../components/billing/modal/ConfirmationModal';
import { useUserDetialsContext } from '../context/user-details';
import { ReactComponent as Spinner } from './spinner.svg';

import confirmSetupIntent from '../utils/billing/confirmSetupIntent';
import { confirmPayment } from '../utils/billing/confirmPayment';
import redirectToLogin from '../utils/token';
import cookieVerify from '../utils/cookie';
import { verify } from '../utils/verify';
import CardInputModal from '../components/billing/modal/CardInputModal';

function SimpleLayout() {
  const [tokenVerified, setTokenVerified] = useState(false);
  const { updateUserDetails } = useUserDetialsContext();

  const location = useLocation();
  const stripe = useStripe();
  const navigate = useNavigate();

  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const [isTrialingIntent, setIsTrialingIntent] = useState(false);

  const suretyClickHandler = (choice) => {
    const isYes = choice === 'yes';
    setConfirmationModal(false);
    if (isYes) {
      if (!stripe || !paymentMethodId || !clientSecret) {
        console.log('Stripe, PaymentMethodId, or ClientSecret is null');
        return;
      }
      if (isTrialingIntent) {
        confirmSetupIntent(stripe, clientSecret, paymentMethodId);
      } else {
        confirmPayment(stripe, navigate, clientSecret, paymentMethodId);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const tokenValue = cookieVerify(location);
      if (tokenValue) {
        const data = await verify(tokenValue);
        if (data !== null) {
          const { email, given_name, status, site_tour } = data;
          if (status === 'not_subscribed') {
            updateUserDetails({
              name: given_name,
              email,
              image: null,
              tokenId: tokenValue,
              status,
              site_tour
            });
          } else if (status === 'canceled') {
            const {
              book_token,
              script_token,
              extra_book_tokens,
              extra_script_tokens,
              start_date,
              canceled,
              canceled_at
            } = data;
            updateUserDetails({
              name: given_name,
              email,
              image: null,
              tokenId: tokenValue,
              status,
              canceled,
              start_date,
              canceled_at,
              script_tokens: script_token,
              book_tokens: book_token,
              extra_book_tokens,
              extra_script_tokens,
              site_tour
            });
          } else if (status === 'past_due' || status === 'incomplete') {
            const {
              book_token,
              script_token,
              extra_book_tokens,
              extra_script_tokens,
              action,
              start_date,
              canceled,
              current_period_start,
              canceled_at,
              current_period_end
            } = data;
            if (action) {
              if (action === 'requires_payment_method') {
                const { card } = data;
                if (card) {
                  const { client_secret } = data;
                  setClientSecret(client_secret);
                  setShowCardModal(true);
                } else {
                  // Update Customer Portal Card Details - Message
                  toast.error(
                    `Please update your card details. https://portal.fullfr.me/p/login/8wM6s5edibn46vm5kk`
                  );
                }
              }
              if (action === 'requires_action') {
                const { client_secret, payment_method } = data;
                setClientSecret(client_secret);
                setPaymentMethodId(payment_method);
                setConfirmationModal(true);
              }
            }
            updateUserDetails({
              name: given_name,
              email,
              image: null,
              tokenId: tokenValue,
              status,
              action,
              canceled,
              start_date,
              current_period_start,
              canceled_at,
              current_period_end,
              script_tokens: script_token,
              book_tokens: book_token,
              extra_book_tokens,
              extra_script_tokens,
              site_tour
            });
          } else if (status === 'trialing') {
            const {
              book_token,
              script_token,
              extra_book_tokens,
              extra_script_tokens,
              action,
              start_date,
              canceled,
              current_period_start,
              canceled_at,
              current_period_end
            } = data;
            if (action) {
              if (action === 'requires_payment_method') {
                const { card } = data;
                if (card) {
                  const { client_secret } = data;
                  setClientSecret(client_secret);
                  setShowCardModal(true);
                } else {
                  // Update Customer Portal Card Details - Message
                  toast.error(
                    `Please update your card details. https://portal.fullfr.me/p/login/8wM6s5edibn46vm5kk`
                  );
                  // navigate("https://portal.fullfr.me/p/login/8wM6s5edibn46vm5kk");
                }
              }
              if (action === 'requires_action') {
                const { client_secret, payment_method } = data;
                setIsTrialingIntent(true);
                setClientSecret(client_secret);
                setPaymentMethodId(payment_method);
                setConfirmationModal(true);
              }
            }
            updateUserDetails({
              name: given_name,
              email,
              image: null,
              tokenId: tokenValue,
              status,
              action,
              canceled,
              start_date,
              current_period_start,
              canceled_at,
              current_period_end,
              script_tokens: script_token,
              book_tokens: book_token,
              extra_book_tokens,
              extra_script_tokens,
              site_tour
            });
          } else {
            const {
              book_token,
              script_token,
              extra_book_tokens,
              extra_script_tokens,
              start_date,
              canceled,
              current_period_start,
              canceled_at,
              current_period_end
            } = data;
            updateUserDetails({
              name: given_name,
              email,
              image: null,
              tokenId: tokenValue,
              status,
              canceled,
              start_date,
              current_period_start,
              canceled_at,
              current_period_end,
              script_tokens: script_token,
              book_tokens: book_token,
              extra_book_tokens,
              extra_script_tokens,
              site_tour
            });
          }
          setTokenVerified(true);
        } else {
          setTokenVerified(false);
          redirectToLogin();
        }
      } else {
        setTokenVerified(false);
        redirectToLogin();
      }
    };
    fetchData();
    setTimeout(() => {
      fetchData();
    }, 5000);
  }, []);

  return (
    <main id="pageContainer" className="w-full z-20">
      {!tokenVerified ? (
        <div className="flex items-center justify-center h-screen">
          <Spinner className="h-28 w-28 sm:h-40 sm:w-40 !text-gray-500 fill-primary animate-spin" />
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          <Outlet />
          <ConfirmationModal
            confirmationModal={confirmationModal}
            setConfirmationModal={setConfirmationModal}
            suretyClickHandler={suretyClickHandler}
            subHeading="Confirm Subscription"
            message="Please confirm you would like to start your paid subscription for $25? You will be charged immediately and receive an additional 10 script credits and 5 book credits."
          />
        </div>
      )}
      <CardInputModal
        clientSecret={clientSecret}
        showCardModal={showCardModal}
        setShowCardModal={setShowCardModal}
      />
    </main>
  );
}

export default SimpleLayout;
