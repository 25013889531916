import { useState } from 'react';
import { CheckCircledIcon, CrossCircledIcon, ProcessingIconDashed } from '../icons/icons';
import Dropdown from '../submissions-database/dropdown/Dropdown';

import { useSubmissionsContext } from '../../context/submissions';
import MultiEntryField from '../submissions-database/MultiEntryField';
import RenderActionIcons from '../submissions-database/RenderActionIcons';
import SingleEntryField from '../submissions-database/SingleEntryField';
import SubmittedByDropdown from '../submissions-database/SubmittedByDropdown';
import DataContainer from './DataContainer';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import RenderStatus from './RenderStatus';

const SubmissionsTableRow = ({ item, index, isLast, dataClass, isArchiveTable }) => {
  const { setSubmissionData, hiddenColumns } = useSubmissionsContext();
  const status = item?.status;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [currentDropdown, setCurrentDropdown] = useState('');
  const [editingField, setEditingField] = useState({
    field: '',
    itemId: ''
  });

  const isHidden = (label) => {
    return hiddenColumns?.includes(label);
  };

  return (
    <tr
      className={`hover:bg-[rgba(0,1,32,0.47)] font-[Arial] table-row transition-all duration-500 ease-in-out ${!isLast && 'border-b'} border-[#1D2939] text-start`}>
      <DataContainer className="!min-w-0 !pl-2 !px-1 py-3.5">
        {!isHidden('Title') && (
          <Dropdown
            item={item}
            values={['SCRIPT', 'BOOK', 'SERIES']}
            defaultValue={item?.type}
            dropdownClass="!w-28"
            title="Type"
            label="type"
            currentDropdown={currentDropdown}
            setCurrentDropdown={setCurrentDropdown}
            isSubmissionType
            index={index}
          />
        )}
      </DataContainer>
      {!isHidden('Title') && (
        <DataContainer
          className={`${item?.status?.toLowerCase() === 'processing' && 'col-span-5'}`}>
          <SingleEntryField
            defaultValue={item.title}
            fieldName={'title'}
            item={item}
            editingField={editingField}
            setEditingField={setEditingField}
          />
        </DataContainer>
      )}
      {!isHidden('Writers/Directors/Producers') && (
        <DataContainer className={`${dataClass} whitespace-nowrap text-nowrap`}>
          {!(status?.toLowerCase() === 'processing' || status?.toLowerCase() === 'failed') && (
            <MultiEntryField
              fieldNames={['writer', 'directors', 'producers']}
              item={item}
              editingField={editingField}
              setEditingField={setEditingField}
            />
          )}
        </DataContainer>
      )}
      {!isHidden('Submitted by') && (
        <DataContainer>
          {!(status?.toLowerCase() === 'processing' || status?.toLowerCase() === 'failed') && (
            <div className="flex items-center gap-2">
              <SubmittedByDropdown
                item={item}
                currentDropdown={currentDropdown}
                setCurrentDropdown={setCurrentDropdown}
              />
            </div>
          )}
        </DataContainer>
      )}
      {!isHidden('Genres') && (
        <DataContainer>
          {!(status?.toLowerCase() === 'processing' || status?.toLowerCase() === 'failed') && (
            <Dropdown
              item={item}
              values={[
                'Arthouse',
                'Biopic',
                'Slasher',
                'Paranormal',
                'Found Footage',
                'Psychological',
                'Dark Comedy',
                'Slapstick',
                'Heist',
                'Thriller',
                'Musical',
                'Non-Fiction',
                'Educational',
                'Technical',
                'Action',
                'Adventure',
                'Comedy',
                'Drama',
                'Horror',
                'Romance',
                'Science Fiction',
                'Fantasy',
                'Documentary',
                'Film Noir',
                'Western',
                'Historical',
                'Crime'
              ]}
              defaultValue={item?.genre}
              dropdownClass="!w-36"
              title="Genre"
              label="genre"
              currentDropdown={currentDropdown}
              setCurrentDropdown={setCurrentDropdown}
              isMultiEntry
              isSubmissionType
              index={index}
            />
          )}
        </DataContainer>
      )}
      {!isHidden('Logline') && (
        <DataContainer dataClass={`${dataClass}`}>
          {!(status?.toLowerCase() === 'processing' || status?.toLowerCase() === 'failed') && (
            <SingleEntryField
              defaultValue={item?.logline}
              fieldName={'logline'}
              textLength={10}
              lineBreakLength={30}
              item={item}
              editingField={editingField}
              setEditingField={setEditingField}
              isTextArea
            />
          )}
        </DataContainer>
      )}
      {!isHidden('Note') && (
        <DataContainer dataClass={`${dataClass}`}>
          {!(status?.toLowerCase() === 'processing' || status?.toLowerCase() === 'failed') && (
            <SingleEntryField
              defaultValue={item?.note}
              fieldName={'note'}
              textLength={10}
              lineBreakLength={30}
              item={item}
              editingField={editingField}
              setEditingField={setEditingField}
              isTextArea
            />
          )}
        </DataContainer>
      )}
      {!isHidden('Read') && (
        <DataContainer className={`${dataClass}`}>
          {!(status?.toLowerCase() === 'processing' || status?.toLowerCase() === 'failed') && (
            <Dropdown
              item={item}
              values={['Unread', 'Read']}
              defaultValue={item?.read ? 'Read' : 'Unread'}
              containerClass="w-20"
              title="Read"
              label="read"
              isBoolean
              currentDropdown={currentDropdown}
              setCurrentDropdown={setCurrentDropdown}
              index={index}
            />
          )}
        </DataContainer>
      )}
      {!isHidden('Responded') && (
        <DataContainer className={`${dataClass}`}>
          {!(status?.toLowerCase() === 'processing' || status?.toLowerCase() === 'failed') && (
            <Dropdown
              item={item}
              values={['No Response', 'Responded']}
              defaultValue={item?.response_status ? 'Responded' : 'No Response'}
              containerClass="w-28"
              title="Response Status"
              label="response_status"
              isBoolean
              currentDropdown={currentDropdown}
              setCurrentDropdown={setCurrentDropdown}
              index={index}
            />
          )}
        </DataContainer>
      )}
      {!isHidden('Priority') && (
        <DataContainer className={`${dataClass}`}>
          {!(status?.toLowerCase() === 'processing' || status?.toLowerCase() === 'failed') && (
            <Dropdown
              item={item}
              values={['Project Drafts', 'Project Samples', 'Available Material', 'General']}
              defaultValue={item?.priority || 'General'}
              onValueChange={() => {}}
              containerClass="w-24"
              title="Priority"
              label="priority"
              currentDropdown={currentDropdown}
              setCurrentDropdown={setCurrentDropdown}
              index={index}
            />
          )}
        </DataContainer>
      )}
      {!isHidden('Date') && (
        <DataContainer className={`${dataClass} px-2`}>
          <div className="flex items-center gap-2">
            {renderStatusIcon(item?.status)}
            <RenderStatus item={item} />
          </div>
        </DataContainer>
      )}
      <DataContainer className={`${dataClass} !w-[1%] pr-4`}>
        <div className="flex items-center gap-2">
          <RenderActionIcons
            item={item}
            setSubmissionData={setSubmissionData}
            isArchiveTable={isArchiveTable}
            setConfirmationModal={setConfirmationModal}
          />
          <DeleteConfirmationModal
            item={item}
            confirmationModal={confirmationModal}
            setConfirmationModal={setConfirmationModal}
          />
        </div>
      </DataContainer>
    </tr>
  );
};

export default SubmissionsTableRow;

function renderStatusIcon(status) {
  if (status?.toLowerCase() === 'completed') {
    return <CheckCircledIcon />;
  } else if (status?.toLowerCase() === 'processing') {
    return <ProcessingIconDashed className="spin" />;
  } else if (status?.toLowerCase() === 'failed') {
    return <CrossCircledIcon />;
  } else {
    return null;
  }
}
