const SUCCESS_GREEN = "#37FFAC";
const DANGER_RED = "#FF978F";
const WARNING_YELLOW = "#FFE5A0";
const INFO_BLUE = "#C6DBE1";
const PURPLE = "#AA80FF";
const BLUE = "#33ADFF";

const createSemiTransparent = (color) => `${color}1A`;

export const STATUS_COLORS = {
  sucess: {
    circleColor: "#05CF7B",
    textColor: SUCCESS_GREEN,
    backgroundColor: createSemiTransparent(SUCCESS_GREEN),
  },
  danger: {
    circleColor: "#F75B50",
    textColor: DANGER_RED,
    backgroundColor: createSemiTransparent(DANGER_RED),
    dashad: true,
  },
  warning: {
    circleColor: WARNING_YELLOW,
    textColor: WARNING_YELLOW,
    backgroundColor: createSemiTransparent(WARNING_YELLOW),
    dashad: true,
  },
  info: {
    circleColor: INFO_BLUE,
    textColor: INFO_BLUE,
    backgroundColor: createSemiTransparent(INFO_BLUE),
  },
  purple: {
    circleColor: PURPLE,
    textColor: PURPLE,
    backgroundColor: createSemiTransparent(PURPLE),
  },
  blue: {
    circleColor: BLUE,
    textColor: BLUE,
    backgroundColor: createSemiTransparent(BLUE),
  },
};