import React from 'react';
import { useSubmissionsContext } from '../../context/submissions';
import { ChevronDownIcon } from '../icons/icons';

const SubmissionsTableHead = ({
  headings = [],
  headingClass = '',
  headClass = '',
  sortingCriteria,
  updateSortingCriteria
}) => {
  const { hiddenColumns } = useSubmissionsContext();

  const renderHeadingLabel = (heading) => {
    if (heading.sortable) {
      return (
        <button
          type="button"
          onClick={() => {
            updateSortingCriteria(heading?.name?.toLowerCase());
          }}
          className="hover:text-primary-light group outline-none whitespace-nowrap">
          <span>{heading.label}</span>
          <span className={`ml-0.5 xl:ml-2`}>
            <ChevronDownIcon
              className={`inline opacity-0 group-hover:opacity-100 text-primary-light ${sortingCriteria.fieldName === heading?.name?.toLowerCase() && sortingCriteria.type === 'desc' ? 'rotate-180' : ''} transition-all duration-300`}
            />
          </span>
        </button>
      );
    } else {
      return <span>{heading.label}</span>;
    }
  };

  const renderHeadings = () => {
    return headings.map((heading, index) => {
      if (hiddenColumns?.includes(heading?.label)) {
        return null;
      }
      return (
        <th
          scope="col"
          key={heading.name + index}
          className={`whitespace-nowrap pr-4 py-4 text-xs lg:text-xs xl:text-sm font-medium text-[#98A2B3] text-start font-[Arial] ${headingClass} ${heading.customHeadingClass}`}>
          {renderHeadingLabel(heading)}
        </th>
      );
    });
  };

  return (
    <thead className={`bg-[#F2F4F70D] ${headClass}`}>
      <tr>{renderHeadings()}</tr>
    </thead>
  );
};

export default SubmissionsTableHead;
