import { useEffect, useState } from 'react';

import { TableSpinner } from '../spinner/Spinner';

import { sortDataByFields } from '../../utils/sortDataByFields';
import SubmissionsTableHead from './SubmissionsTableHead';
import SubmissionsTableRow from './SubmissionsTableRow';
import { useSubmissionsContext } from '../../context/submissions';

export default function SubmissionsTable({
  headings,
  filterSubmissionData,
  loading,
  headClass,
  headingClass,
  dataClass,
  emptyContent,
  isArchiveTable
}) {
  const sortCriteria = localStorage.getItem('sortingCriteria');
  const [sortingCriteria, setSortingCriteria] = useState(
    sortCriteria ? JSON.parse(sortCriteria) : { fieldName: 'created_at', type: 'desc' }
  );
  const { submissionsData, setSubmissionData} = useSubmissionsContext();

  const updateSortingCriteria = (fieldName) => {
    setSortingCriteria((prevSortingCriteria) => {
      let newType = 'desc';
      if (prevSortingCriteria?.fieldName === fieldName && prevSortingCriteria?.type === 'desc') {
        newType = 'asc';
      }
      const newCriteria = { fieldName, type: newType };

      // Saving the criteria in the local storage
      localStorage.setItem('sortingCriteria', JSON.stringify(newCriteria));
      return newCriteria;
    });
  };

  useEffect(() => {
    const sortedData = sortDataByFields(submissionsData, sortingCriteria);
    if (setSubmissionData && sortedData) {
      setSubmissionData(sortedData);
    }
  }, [sortingCriteria]);

  return (
    <table className="divide-y divide-[#1D2939] w-full">
      <SubmissionsTableHead
        headings={headings}
        headingClass={headingClass}
        headClass={headClass}
        sortingCriteria={sortingCriteria}
        updateSortingCriteria={updateSortingCriteria}
      />
      <tbody className="relative w-full">
        {loading ? (
          <TableSpinner
            rowClass="!static !table-row text-center !h-[50vh]"
            dataClass="!h-[40vh]"
            colSpan={headings.length}
          />
        ) : (
          filterSubmissionData?.length !== 0 &&
          !loading &&
          filterSubmissionData?.map((item, index) => (
            <SubmissionsTableRow
              item={item}
              key={item._id || index}
              isLast={index === filterSubmissionData.length - 1}
              dataClass={dataClass}
              isArchiveTable={isArchiveTable}
              index={index}
            />
          ))
        )}
        {filterSubmissionData?.length === 0 && !loading && (
          <tr className="table-row min-h-[40vh] z-[20]">
            <td className="text-center h-[40vh] m-auto" colSpan={headings.length}>
              {emptyContent || 'No data available'}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
