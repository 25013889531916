import React, { useState } from 'react';
import { useUserDetialsContext } from '../../context/user-details';
import { returnCapitalizedIndexedCharacter } from '../../utils/returnCapitalizedIndexedCharacter';
import redirectToLogin from '../../utils/token';
import { LogoutIcon } from '../icons/icons';
import ConfirmationModal from '../Modal/ConfirmationModal';

const UserDetails = () => {
  const { userDetails } = useUserDetialsContext();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { status, image, name, email } = userDetails;

  const handleLogout = () => {
    setShowLogoutModal(false);
    // Clearing the session cookies for user logout
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    redirectToLogin();
  };

  const returnUserProfile = () => {
    if (status) {
      if (image) {
        return <img src={image} alt="Profile" className="h-full w-full" />;
      } else {
        return (
          <div className="h-full w-full bg-[rgba(255,255,255,0.1)] text-[0.9rem] text-primary flex items-center justify-center gap-[0.09rem]">
            <span>{returnCapitalizedIndexedCharacter(0, name)}</span>
            <span>{returnCapitalizedIndexedCharacter(1, name)}</span>
          </div>
        );
      }
    } else {
      return <div className="w-full h-full bg-gray-300 rounded-full animate-pulse" />;
    }
  };

  const returnUserDetails = () => {
    if (status) {
      return (
        <div className="flex flex-col">
          <span className="text-sm md:text-[13px] leading-5 font-semibold text-white truncate ...">
            {name?.length <= 15 ? name : name?.substring(0, 15) + '...'}
          </span>
          <span className="text-xs md:text-sm leading-4 text-[rgba(255,255,255,0.5)] truncate ...">
            {email?.length <= 14 ? email : email?.substring(0, 14) + '...'}
          </span>
        </div>
      );
    } else {
      return (
        <div className="hidden sm:flex flex-col gap-1 animate-pulse">
          <span className="py-2.5 w-32 bg-gray-300 rounded-lg" />
          <span className="py-2 w-32 bg-gray-300 rounded-lg" />
        </div>
      );
    }
  };

  const returnLogoutButton = () => {
    if (status) {
      return (
        <button type="button" onClick={() => setShowLogoutModal(true)}>
          <LogoutIcon className="hover:text-primary transition-all duration-200" />
        </button>
      );
    } else {
      return <span className="py-2.5 px-3 bg-gray-300 rounded-lg animate-pulse" />;
    }
  };

  return (
    <div className="flex gap-2 justify-between items-center px-3">
      <div className="flex gap-2 items-center">
        <div className="h-8 w-8 rounded-full overflow-hidden">{returnUserProfile()}</div>
        {returnUserDetails()}
      </div>
      {returnLogoutButton()}
      <ConfirmationModal
        title="Are you sure you want to logout?"
        subTitle="If so, select “confirm” to logout."
        confirmationModal={showLogoutModal}
        setConfirmationModal={setShowLogoutModal}
        onConfirm={handleLogout}
      />
    </div>
  );
};

export default UserDetails;
