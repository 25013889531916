import axios from 'axios';
import { isToday, isYesterday, subDays } from 'date-fns';
import toast from 'react-hot-toast';
import cookieVerify from '../utils/cookie';
import redirectToLogin from './token';

export const getChatHistory = async (formData, navigate, setMessages, setFileName) => {
  const tok = cookieVerify(location);

  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BACKEND_URL}chat/history/`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tok}`
      },
      withCredentials: true,
      params: formData
    });

    const { data } = response;
    const chatHistory = data?.chat_history;
    const fileName = data.name;

    let newMessages = [];
    if (chatHistory?.length > 0) {
      chatHistory.forEach((chat) => {
        const userMessage = chat.question;
        const botMessage = chat.answer;
        newMessages.push({ text: userMessage });
        newMessages.push({ text: botMessage, botMessage: true });
      });
    }

    setMessages(newMessages);
    setFileName(fileName);
  } catch (error) {
    handleError(error, navigate);
  }
};

export const sendChatQuery = async (
  formData,
  navigate,
  setMessages,
  updatedMessages,
  setQueryInProgress
) => {
  const tok = cookieVerify(location);

  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BACKEND_URL}chat/query/`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tok}`
      },
      withCredentials: true,
      data: formData
    });

    const { data } = response;
    const answer = data?.answer;

    const newUpdatedMessages = [...updatedMessages, { text: answer, botMessage: true }];

    setMessages(newUpdatedMessages);
  } catch (error) {
    handleError(error, navigate);
  } finally {
    setQueryInProgress(false);
  }
};

const handleError = (error, navigate) => {
  console.error('error', error?.message || 'Something went wrong');

  if (error?.response?.status === 400) {
    toast.error(error.message);
    setTimeout(() => navigate('/query'), 3000);
  }

  if (error?.response?.status === 401) {
    toast.error('Login Session expired. Please log in again to continue.');
    setTimeout(redirectToLogin, 2000);
  }
};

export const groupChatsByDate = (chats) => {
  const today = [];
  const yesterday = [];
  const previous7Days = [];

  chats.forEach((session) => {
    const dateInISO = session?.created_at + 'Z';
    const sessionDate = new Date(dateInISO);

    if (isToday(sessionDate)) {
      today.push(session);
    } else if (isYesterday(sessionDate)) {
      yesterday.push(session);
    } else if (sessionDate >= subDays(new Date(), 7)) {
      previous7Days.push(session);
    }
  });

  return { today, yesterday, previous7Days };
};

export const createChatBySubmission = async ( submission_id ) => {
  const tok = cookieVerify(location);
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}chat/submission/create/`, {submission_id}, {
      headers: {
        Authorization: `Bearer ${tok}`,
      },
    });
    return response;

}


export const createChatWithFile = async (selectedFile, location) => {
  const formData = new FormData();
  formData.append('file', selectedFile);
  formData.append('pdf_name', selectedFile.name);
  const tok = cookieVerify(location);

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}chat/create/`, formData, {
      method: 'POST',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${tok}`
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};


export const fetchChatListing = async (location) => {
  const tok = cookieVerify(location);
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}chat/list/`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${tok}`
      }
    });
    return response;
  } catch (error) {
    console.error('Error fetching sessions:', error);
    throw error;
  }
};