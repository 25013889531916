import React from 'react'

const RenderInitials = ({ companyName }) => {
  return ( 
    companyName && 
      <div className='w-7 h-7 text-[8px] bg-[#292A35] rounded-full flex items-center justify-center text-white font-inter font-semibold border border-[rgba(255,255,255,0.15)]'>
          {companyName.split(' ').map((word) => word[0]).join('')}
      </div>
  )
}

export default RenderInitials
