import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getSubmissionsDataApi, getSubmissionsLongDataApi } from '../api/submissions-database';
import FileUpload from '../components/coverage/FileUpload';
import { RightChevronIcon } from '../components/icons/icons';
import EmbeddingModal from '../components/query/EmbeddingModal';
import Card from '../components/UI/Card';
import { useSubmissionsContext } from '../context/submissions';
import { useUserDetialsContext } from '../context/user-details';
import ChatListing from '../query-script/components/ChatListing';
import QueryScript from '../query-script/QueryScript';
import cookieVerify from '../utils/cookie';
import { sortDataByFields } from '../utils/sortDataByFields';
import redirectToLogin from '../utils/token';
import { createChatBySubmission, createChatWithFile, fetchChatListing } from '../utils/chat';
import isMoreThanAnHourFormNow from '../utils/isMoreThanAnHourFormNow';

const Query = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const chat_id = searchParams.get('chat_id');
  const { userDetails } = useUserDetialsContext();
  const { status } = userDetails;
  const [selectedFile, setSelectedFile] = useState(null);
  const [embeddingModal, setEmbeddingModal] = useState(false);
  const [reportGeneration, setReportGeneration] = useState(false);
  const [showQueryScript, setShowQueryScript] = useState(false);
  const { submissionsData, setSubmissionData, setLoading } = useSubmissionsContext();
  const [isHistoryClosed, setIsHistoryClosed] = useState(true);
  const [chatListingLoading, setChatListingLoading] = useState(false);
  const [chatListing, setChatListing] = useState([]);
 
  const fetchAndSetChatListing = async () => {
    setChatListingLoading(true);
    try {
      const res = await fetchChatListing(location);
      const chatList = res?.data?.chat_list
      setChatListing(chatList);
    } catch (error) {
      console.error(error);
    }
    setChatListingLoading(false);
  };
 
const queryScriptHandler = async () => {
  try {
    setEmbeddingModal(true);
    setReportGeneration(true);
    const isFile = !!selectedFile.name;

    const res = isFile
      ? await createChatWithFile(selectedFile, location)
      : await createChatBySubmission(selectedFile._id);

    const chat_id = res?.data?.data?.chat_id;
    const chatName = selectedFile?.name || selectedFile?.title
    const currentData =new  Date()
    const isoDate = currentData.toISOString().slice(0,-1)
    setChatListing(prevChatListing => [
      { name: chatName, _id: chat_id ,created_at:isoDate  }, 
      ...prevChatListing, 
    ]);
    setEmbeddingModal(false);
    setReportGeneration(false);

    navigate({
      pathname: '/query',
      search: createSearchParams({ chat_id }).toString()
    });
  } catch (error) {
    console.error('error', error);

    if (error?.response?.status === 400) {
      toast.error(
        'Something went wrong. Please try again. If the error persists, please contact our support'
      );
    }

    if (error?.response?.status === 401) {
      toast.error('Session expired. Please log in again to continue.');
      setTimeout(() => {
        redirectToLogin();
      }, 2000);
    }
  } finally {
    setReportGeneration(false);
    setEmbeddingModal(false);
  }
};

  const closeHistoryHandler = () => {
    localStorage.setItem('historyClosed', true);
    setIsHistoryClosed(true);
  };

  const openHistoryHandler = () => {
    localStorage.setItem('historyClosed', false);
    setIsHistoryClosed(false);
  };

  useEffect(() => {
    if (status === 'not_subscribed') {
      navigate('/script-coverage');
    } else if (!chat_id) {
      navigate('/query');
      setShowQueryScript(false);
    } else {
      setShowQueryScript(true);
    }
  }, [status, chat_id, navigate]);

  useEffect(() => {
    fetchAndSetChatListing()
  },[]);

  const setAndSortSubmissionsList = (submissionList) => {
    const sortCriteria = localStorage.getItem('sortingCriteria');
    if (sortCriteria) {
      const sortedData = sortDataByFields(submissionList, JSON.parse(sortCriteria));
      setSubmissionData(sortedData);
    } else {
      setSubmissionData(submissionList);
    }
  };

  const getSubmissionsData = async (isFirstRun = true) => {
    try {
      const response = isFirstRun
        ? await getSubmissionsDataApi(location)
        : await getSubmissionsLongDataApi(location);
      const submissionList = response?.data?.data;
      setAndSortSubmissionsList(submissionList);
      const isProcessing = submissionList.some((item) => {
        return item.status === 'PROCESSING' && !isMoreThanAnHourFormNow(item?.created_at);
      });
      if (isProcessing) {
        getSubmissionsData(false);
      }
    } catch (error) {
      console.log('error', error);
      if (error?.response?.status === 408) {
        getSubmissionsData(false);
      } else if (error?.response?.status === 401) {
        toast.error('Session expired. Please log in again to continue.');
        setTimeout(() => {
          redirectToLogin();
        }, 2000);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submissionsData?.length === 0) {
      getSubmissionsData();
    }
    const historyClosed = localStorage?.getItem('historyClosed');
    if (historyClosed) {
      const parsedState = JSON?.parse(historyClosed);
      setIsHistoryClosed(parsedState);
    } else {
      setIsHistoryClosed(true);
    }
  }, []);

  return (
    <div>
      <button
        type="button"
        onClick={openHistoryHandler}
        className={`${!isHistoryClosed ? 'invisible opacity-0' : 'visible opacity-100'} flex items-center gap-2 font-inter group cursor-pointer`}>
        <h3 className="text-sm text-gray-300 font-bold group-hover:text-primary transition-all duration-300">
          History
        </h3>
        <RightChevronIcon className="group-hover:text-primary transition-all duration-300" />
      </button>
      <div className={`flex h-[91vh]`}>
        <div
          className={`${isHistoryClosed ? 'w-0 invisible opacity-0' : 'md:w-[31%] lg:w-1/3 2xl:w-1/5 block opacity-100'} h-full bg-[#D9D9D91A] rounded-lg transition-all duration-500`}>
          <ChatListing 
          chatListing={chatListing}
          chatListingLoading={chatListingLoading}
          closeHistoryHandler={closeHistoryHandler} />
        </div>
        <div className="flex-1 overflow-auto p-6">
          {!showQueryScript ? (
            <>
              <Card>
                <FileUpload
                  title="Upload PDF"
                  subtitle="Upload your screenplay or book now to start querying it. You can ask questions like “Which character double crosses the main character?“ or “What is internal conflict of main Character?”."
                  setReportGenerationModal={setEmbeddingModal}
                  reportGeneration={reportGeneration}
                  setReportGeneration={setReportGeneration}
                  setUploadedFile={setSelectedFile}
                  queryScriptHandler={queryScriptHandler}
                  isQuery
                />
              </Card>
              <EmbeddingModal
                reportGenerationModal={embeddingModal}
                setReportGenerationModal={setEmbeddingModal}
                selectedFile={selectedFile}
              />
            </>
          ) : (
            <QueryScript chat_id={chat_id} selectedFile={selectedFile} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Query;
