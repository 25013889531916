import { useEffect } from 'react';

import ModalHeader from './ModalHeader';

import { CheckBorderedIcon, CrossBorderedIcon } from '../icons/icons';
import Button from '../UI/Button';
import Modal from '../UI/Modal';

const ConfirmationModal = ({
  title = '',
  subTitle = '',
  confirmationModal,
  setConfirmationModal,
  onConfirm,
  bodyContent
}) => {
  useEffect(() => {
    const escFunction = (e) => {
      if (e.keyCode === 27) {
        setConfirmationModal(false);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Modal onClose={() => setConfirmationModal(false)} showModal={confirmationModal}>
      <div className="pb-4 flex flex-col items-center justify-center gap-4 sm:gap-7 w-full">
        <ModalHeader text="Confirmation" />
        <div className="flex flex-col md:flex-row gap-4 items-center justify-between px-4 md:px-4 lg:px-4 xl:px-6 w-full font-semibold">
          <div className="">
            <span>{title}</span>
            <span className="text-gray-600 block lg:text-[0.94rem] xl:text-base">{subTitle}</span>
          </div>
          {bodyContent}
        </div>
        <div className="grid grid-cols-2 justify-center gap-4">
          <Button
            className="flex items-center justify-center gap-1 sm:gap-2 !py-2 !px-2 md:!px-5 !text-gray-600 !border !border-gray-400 !bg-transparent hover:!bg-gray-100 hover:!text-black hover:!border-gray-800 font-semibold"
            onClick={() => setConfirmationModal(false)}>
            <CrossBorderedIcon />
            <span>Cancel</span>
          </Button>
          <Button
            className="flex items-center justify-center gap-1 sm:gap-2 !py-2.5 !px-2 md:!px-5 font-semibold"
            onClick={onConfirm}>
            <CheckBorderedIcon />
            <span>Confirm</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
