export const content = [
  {
    title: '1. Privacy notice',
    description: (
      <span>
        Please refer to our Privacy notice at{' '}
        <a
          href="https://www.fullfr.me/privacy"
          target="_blank"
          rel="noreferrer"
          className="text-primary"
        >
          https://www.fullfr.me/privacy
        </a>{' '}
        for information on how we collect and disclose information from our users, what data we
        store, and how we use that data. You agree that your use of FullFrame and the Services is
        subject to our Privacy notice.
      </span>
    )
  },
  {
    title: '2. Changes to terms',
    description:
      'We may update these Terms at any time, in our sole discretion. If we do so, we’ll let you know either by posting the updated Terms on the Site or through other communications. It’s important that you review the Terms whenever we update them or you use the Site. If you continue to use the Site after we have posted updated Terms, you are agreeing to be bound by the updated Terms. If you don’t agree to be bound by the updated Terms, then you may not use the Services anymore.'
  },
  {
    title: '3. License to Use the Services',
    description:
      'Provided that you are 18 years or older and capable of forming a binding contract with FullFrame and are not barred from using the Services under applicable law, and conditioned upon your compliance with these Terms, FullFrame grants you a limited, non-exclusive, non-transferable, non-sublicensable license to use the Services. It’s important that you provide us with accurate, complete and up-to-date information for your Account. You agree to update such information to keep it accurate, complete and up-to-date. If you don’t, we might have to suspend or terminate your Account. You agree that you won’t disclose your Account password to anyone and you’ll notify us immediately of any unauthorized use of your Account. You’re responsible for all activities that occur under your Account, whether or not you know about them.'
  },
  {
    title: '4.1. User content',
    description:
      '“You may provide input to the Services (“Input”), and receive output generated and returned by the Services based on the Input (“Output”). Input and Output are collectively “Content.” As between the parties and to the extent permitted by applicable law, you own all Input. Subject to your compliance with these Terms, FullFrame hereby assigns to you all its right, title and interest in and to Output. You are responsible for Content, including for ensuring that it does not violate any applicable law or these Terms. For example, a pdf of script you wrote that you posted onto the FullFrame Site. FullFrame does not claim any ownership rights in any User Content and nothing in these Terms will restrict any rights that you may have to use and exploit your User Content.'
  },
  {
    title: '4.2. Content security',
    description:
      'We do not use Content that you provide to or receive to develop or improve our Services.'
  },
  {
    title: '5. General prohibitions and our enforcement rights',
    description: (
      <span>
        In connection with your use of the Services, you agree not to do any of the following: Post,
        upload, publish, submit or transmit any User Content or other materials that:
        <span className="block">
          <span className="text-primary">(i)</span> infringe, misappropriate or violate a third
          party’s patent, copyright, trademark, trade secret, moral rights or other intellectual
          property rights, or rights of publicity or privacy;
        </span>
        <span className="block">
          <span className="text-primary">(ii)</span> violate, or encourage any conduct that would
          violate, any applicable law or regulation or would give rise to civil liability;
        </span>
        <span className="block">
          <span className="text-primary">(iii)</span> are fraudulent, false, misleading or
          deceptive;
        </span>
        <span className="block">
          <span className="text-primary">(iv)</span> are defamatory, obscene, pornographic, vulgar
          or offensive;
        </span>
        <span className="block">
          <span className="text-primary">(v)</span> promote discrimination, bigotry, racism, hatred,
          harassment or harm against any individual or group;
        </span>
        <span className="block">
          <span className="text-primary">(vi)</span> are violent or threatening or promote violence
          or actions that are threatening to any person or entity; or
        </span>
        <span className="block">
          <span className="text-primary">(vii)</span> promote illegal or harmful activities or
          substances; Access, tamper with, or use non-public areas of the Services, FullFrame’s
          computer systems, or the technical delivery systems of FullFrame’s providers; Attempt to
          probe, scan or test the vulnerability of the Services, any system or network of FullFrame
          or its providers, or breach any security or authentication measures of FullFrame or its
          providers; Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent
          any technological measure implemented by FullFrame or any of FullFrame’s providers or any
          other third party (including another user) to protect the Services, or the system or
          network of FullFrame or its providers; Attempt to access or search the Services or
          download materials from the Services through the use of any engine, software, tool, agent,
          device or mechanism (including spiders, robots, crawlers, data mining tools or the like)
          other than the software and/or search agents provided by FullFrame or other generally
          available third-party web browsers; Interfere with, or attempt to interfere with, the
          access of any user, host or network, including, but not limited to, sending a virus,
          overloading, flooding, spamming, or mail-bombing the Services or the system or network of
          any FullFrame provider; Use the Services or any of its component features for competitive
          analysis or benchmarking, or to otherwise develop, sell, license, commercialize or
          contribute to the development or commercialization of any product or service that could,
          directly or indirectly, compete with the Services or any other product or service of
          FullFrame; Impersonate or misrepresent your affiliation with any person or entity; Violate
          any applicable law or regulation; or Encourage or enable any other individual to do any of
          the foregoing. Although we’re not obligated to monitor access to or use of the Services or
          to review or edit any User Content, we have the right to do so for the purpose of
          operating or protecting the Services, to ensure compliance with these Terms and to comply
          with applicable law or other legal requirements. We reserve the right, but are not
          obligated, to remove or disable access to any User Content, at any time and without
          notice, including, but not limited to, if we, at our sole discretion, consider any Content
          to be objectionable or in violation of these Terms. We have the right to investigate
          violations of these Terms or conduct that affects the Services. We may also consult and
          cooperate with law enforcement authorities to prosecute users who violate the law.
        </span>
      </span>
    )
  },
  {
    title: '6. Links to third party websites or resources',
    description:
      'The Services may contain links to third-party websites or resources. We provide these links only as a convenience and are not responsible for the content, products or services on or available from those websites or resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from your use of any third-party websites or resources.'
  },
  {
    title: '7. Termination',
    description: (
      <span>
        We may terminate or suspend your access to and use of the Support Services if you violate
        these Terms, if necessary for us to conduct maintenance on or implement updates to the
        Services, or if we discontinue the existing version of the Services, at any time and without
        notice to you. You may cancel your Account at any time by sending an email to us at{' '}
        <a href="mailto:legal@fullfr.me" className="text-primary">
          legal@fullfr.me
        </a>
        .
      </span>
    )
  },
  {
    title: '8.1 Fees and Billing',
    description:
      'You will pay all fees charged to your account (“Fees”) according to the prices and terms on the applicable pricing page, or as otherwise agreed between us in writing. We have the right to correct pricing errors or mistakes even if we have already issued an invoice or received payment. You will provide complete and accurate billing information including a valid and authorized payment method. We will charge your payment method on an agreed-upon periodic basis, but may reasonably change the date on which the charge is posted. You authorize FulLFrame and its affiliates, and our third-party payment processor(s), to charge your payment method for the Fees. If your payment cannot be completed, we will provide you written notice and may suspend access to the Services until payment is received. Fees are payable in U.S. dollars and are due upon invoice issuance. Payments are nonrefundable except as provided in this Agreement. Unless otherwise stated, Fees do not include federal, state, local, and foreign taxes, duties, and other similar assessments (“Taxes”). You are responsible for all Taxes associated with your purchase, excluding Taxes based on our net income, and we may invoice you for such Taxes. You agree to timely pay such Taxes and provide us with documentation showing the payment, or additional evidence that we may reasonably require. FullFrame uses the name and address in your account registration as the place of supply for tax purposes, so you must keep this information accurate and up-to-date. We may change our prices by posting notice to your account and/or to our website.'
  },
  {
    title: '8.2 Free Trial',
    description:
      'You may not create more than one account to benefit from credits provided in the free trial of the Services. If we believe you are not using the free trial in good faith, we may charge you standard fees or stop providing access to the Services.'
  },
  {
    title: '9. Warranty disclaimers',
    description:
      'TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER FULLFRAME NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE Services WILL BE LIABLE FOR DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE Services, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT FullFrame OR ANY OTHER PARTY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. THE PARTIES HAVE AGREED THAT THESE LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.'
  },
  {
    title: '10. Disclaimers with respect to user components',
    description:
      'WITHOUT LIMITING SECTION 11, THE USER COMPONENTS MADE AVAILABLE BY FULLFRAME ARE PROVIDED ON AN “AS-IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND. WITHOUT LIMITING SECTION 11, WE HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, INCLUDING WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FUNCTIONALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, WHETHER EXPRESS, IMPLIED OR STATUTORY.'
  },
  {
    title: '11. Limitation of liability',
    description:
      'TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER FULLFRAME NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE Services, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT FullFrame OR ANY OTHER PARTY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. WITHOUT LIMITING THE FOREGOING, FullFrame HAS NO RESPONSIBILITY OR LIABILITY FOR USER COMPONENTS OR FOR ANY ACTS OR OMISSIONS BY THIRD PARTIES, INCLUDING WITHOUT LIMITATION, THE OPERABILITY OR INTEROPERABILITY OF SUCH USER COMPONENTS WITH THE SOFTWARE, OR THE SECURITY, ACCURACY, RELIABILITY, DATA PROTECTION AND PROCESSING PRACTICES OF THE PROVIDERS OF ANY USER COMPONENTS. BY ACCESSING OR USING USER COMPONENTS IN CONNECTION WITH THE SOFTWARE, YOU ACKNOWLEDGE THAT YOUR ACCESS AND USE OF USER COMPONENTS ARE AT YOUR SOLE DISCRETION AND RISK. YOU ARE SOLELY RESPONSIBLE FOR VERIFYING THE OPERATION AND PRACTICES OF THE PROVIDER OF USER COMPONENTS AND ITS RESPECTIVE THIRD PARTY AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. THE PARTIES HAVE AGREED THAT THESE LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.'
  },
  {
    title: '12.1. Entire agreement',
    description:
      'These Terms, including the Privacy Policy, constitute the entire and exclusive understanding and agreement between FullFrame and you regarding the Services, and these Terms supersede and replace any and all prior oral or written understandings or agreements between FullFrame and you regarding the same. If any provision of these Terms is held invalid or unenforceable by a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. You may not assign or transfer these Terms, by operation of law or otherwise, without FullFrame’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be void. FullFrame may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.'
  },
  {
    title: '12.2. Notices',
    description:
      'Any notices or other communications provided by FullFrame under these Terms, including those regarding modifications to these Terms, will be given: (i) via email; or (ii) by posting to the Services. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.'
  },
  {
    title: '12.3. Waiver of rights',
    description:
      'FullFrame’s failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of FullFrame. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.'
  },
  {
    title: '13. Contact information',
    description: (
      <span>
        If you have any questions about these Terms or the Services, please contact FullFrame at{' '}
        <a href="mailto:legal@fullfr.me" className="text-primary">
          legal@fullfr.me
        </a>
        .
      </span>
    )
  }
];
