export default (dateInput) => {
  const isoDate = dateInput + 'Z';
  let date = new Date(isoDate);
  if (isNaN(date)) {
    date = new Date(dateInput);
  }
  const oneHourAgo = Date.now() - 60 * 60 * 1000;
  const isOneHourAgo = date.getTime() < oneHourAgo;
  return isOneHourAgo;
};
